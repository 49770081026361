<template>
  <section class="min-w1600">
    <div class="searchwrap">
      <div class="searchBar">
         <div class="box-ui-select searchbar-content">
           <div class="title"><span>{{ $t('searchArea.date') }}</span></div>
           <div class="fx">
             <date-selector :class="'searchbar-content'"
                           @setStartDate="setStartDate"
                           @setEndDate="setEndDate"
                           :startDefault="startDefault"
                           :endDefault="endDefault"
                           :configs="dateConfigs"
                           :isOldYn="true"
                           @setOldYn="setOldYn" :oldYn="reqData.oldYn"/>
            <div class="box-ui-check">
               <div class="ui-check-wrap">
                  <input type="checkbox" id="blankCheck" :checked="reqData.isBlankSpinYn === 'Y'" @change="setBlankSpin">
                  <label for="blankCheck">
                     <i class="fas fa-check"></i>
                     <span>{{ $t('searchArea.idlView') }}</span>
                  </label>
               </div>
            </div>
           </div>
         </div>
      </div>
      <div class="searchBar">
         <div class="box-ui-select searchbar-content">
           <div class="title"><span>{{ $t('table.head.category') }}</span></div>
           <div>
              <select style="margin-right: 5px;" v-model="reqData.idType">
                <option value="NORMAL">{{ $t('searchArea.memberID') }}</option>
                <option value="PTN">{{ $t('searchArea.recommenderId') }}</option>
              </select>
              <input type="text" class="mr-5" v-model="reqData.memId" />
              <input type="text" class="mr-5" v-model="reqData.betId" :placeholder="$t('searchArea.betIds')" />
              <button class="btn-search" type="button" @click="pageSeach">
                <i class="fas fa-search"></i>
              </button>
              <select v-model="currentPage" class="ml-10">
                <option :value="'all'">{{ $t('common.total') }}</option>
                <template v-for="item in tabList" :key="item.code">
                  <option :value="item.code">{{ item.codeName }}</option>
                </template>
              </select>
           </div>
         </div>
      </div>
      <memo></memo>
    </div>
    <div class="main-contents-wrapper">
      <div class="table-wrapper">
        <table class="mainTable">
          <table-head-sorting :headInfo="headInfo" @sort="onSort" :orderColumn="reqData.orderColumn" :orderType="reqData.orderType"/>
          <tbody>
            <template v-if="betList.length !== 0">
              <tr v-for="(item, idx) in betList" :key="idx">
                <td class="roboto">{{ pageInfo.tatal_list_count - (pageInfo.limit * (pageInfo.page - 1)) - idx }}</td>
                <td>
                  <button type="button" class="fc-id btn-link" @click="detailOpen('user', item.memId)">{{ item.memId }}</button>
                </td>
                <td>
                  <span class="game-kind">{{ item.gameCategory }}</span>
                </td>
                <td>{{ item.vendorName }}</td>
                <td>{{ item.gameName }}</td>
                <td class="roboto">{{ item.betIdx }}</td>
                <td class="w15">
                   <div class="btnWrap">
                     <button type="button" class="fc-btid btn-link" @click="betDetailOpen(item)">
                       {{ item.gameIdx }}
                     </button>
                     <button class="copybtn" @click="copyToClipboard(item.gameIdx)"></button>
                   </div>
                </td>
                <td class="roboto">{{ item.regDate }}</td>
                <td class="roboto">{{ numberWithCommas(item.bfMoney) }}</td>
                <!-- todo: 게임구분(바카라 등) 추가 -->
                <td class="roboto">{{item.betAmt }}</td>
                <td class="roboto">{{ numberWithCommas(item.betWinAmt) }}</td>
                <td :class="item.wl ? 'fc-red' : 'fc-blue'" class="roboto">{{ item.wlAmt }}</td>
                <!-- 회원보유금 추가 -->
                <td class="roboto">{{ numberWithCommas(item.afMoney) }}</td>
                <td :class="item.isBlankSpinYn === 'Y' ? 'e-bet': 'e-bet-off'">
                   <div>
                      <template v-if="item.result === '무'">
                        <span class="emptybet box-t">{{ item.result }}</span>
                      </template>
                      <template v-else-if="item.result === '패배'">
                        <span class="emptybet box-l">{{ item.result }}</span>
                      </template>
                      <template v-else-if="item.result === '대기'">
                        <span class="emptybet box-w">{{ $t('common.betdone') }}</span>
                      </template>
                      <template v-else-if="item.result === '대기중'">
                        <span class="emptybet box-w">{{ $t('common.betdone') }}</span>
                      </template>
                      <template v-else>
                         <span class="emptybet">{{ item.result }}</span>
                      </template>
                   </div>
                </td>
              </tr>
            </template>
            <template v-else>
              <td colspan="14">{{ $t('txt.noData') }}</td>
            </template>
          </tbody>
        </table>
      </div>
    </div>
    <pagination v-if="betList.length !== 0" @goPage="goPage" :pageNum="pageInfo.page" :pageSize="pageInfo.count_per_list" :totalCount="pageInfo.tatal_list_count" />
  </section>

	<bet-detail v-if="isDetailOpen" :meta="detailMeta" @close="betDetailClose"></bet-detail>
</template>

<script>
import SearchFilter from '@/components/common/SearchFilter'
import DateSelector from '@/components/common/DateSelector'
import TableHeadSorting from '@/components/main/table/HeadSorting.vue'
import UiCheck from '@/components/ui/UiCheckSet'
import subTitle from '@/components/main/PageSubTitle'
import { betList, getCode } from '@/api/member.js'
import { GAME_CODE_NAME } from '@/libs/constants'
import { numberWithCommas, getDateStr } from '@/libs/utils.js'
import Pagination from '@/components/common/Pagination'
import BetDetail from '@/views/member/bet/pages/betDetail.vue'
import Memo from '@/components/common/memo'

export default {
  name: 'BetList',
  components: {
    BetDetail,
    SearchFilter,
    DateSelector,
    TableHeadSorting,
    UiCheck,
    subTitle,
    Pagination,
    Memo
  },
  data: function () {
    return {
      tableData: {},
      headInfo: {
        fstColumn: false,
        dataList: [
          { key: 'index' },
          { key: 'memId' },
          { key: 'gameType' },
          { key: 'betType' },
          { key: 'gameName' },
          { key: 'betIdx' },
          { key: 'gameUniqueId' },
          { key: 'betDate', sort: true, sortKey: 'regDate' },
          { key: 'preBet' },
          { key: 'betAmt', sort: true },
          { key: 'winAmt', sort: true, sortKey: 'betWinAmt' },
          { key: 'betwlt' },
          { key: 'aftBet' },
          { key: 'status' }
        ]
      },
      srchFiltersProp: {
        selectOptions: [
          {
            useAll: true,
            opts: [
              {
                title: 'id'
              },
              {
                title: 'nick'
              },
              {
                title: 'site'
              }
            ]
          }
        ]
      },
      reqData: {
        page: 1,
        count_per_list: '50',
        betType: 'kgon',
        vendorCode: '1',
        gameCategory: 'slot',
        oldYn: 'N',
        idType: 'NORMAL',
        gameCategory: '',
        orderColumn: '',
        orderType: 'DESC',
        isBlankSpinYn: ''
      },
      order: '',
      pageInfo: {},
      betList: [],
      codeList: [],
      convertData: {},
      gameType: {},
      tabList: {},
      currentPage: '',
      dateConfigs: {
        enableTime: true,
        dateFormat: 'Y-m-d H:i'
      },
      startDefault: '',
      endDefault: '',
      isDetailOpen: false,
      detailMeta: null
    }
  },
  methods: {
    setBlankSpin () {
      if (this.reqData.isBlankSpinYn === 'Y') {
        this.reqData.isBlankSpinYn = ''
      } else {
        this.reqData.isBlankSpinYn = 'Y'
      }
    },
    copyToClipboard (text) {
      navigator.clipboard.writeText(text)
         .then(() => {
            alert('클립보드에 복사했습니다')
         })
    },
    onSort (key, type) {
      this.reqData.orderType = type
      this.reqData.orderColumn = key
      this.lastDataRefector(this.currentPage, 1)
    },
    async betDetailOpen (item) {
      this.detailMeta = item
      this.isDetailOpen = true
    },
    async betDetailClose () {
      this.detailMeta = null
      this.isDetailOpen = false
    },
    async setTabList () {
      const group = this.$route.meta.group
      // console.log(group);
      const gameList = await this.$store.getters.gameList
      // console.log(gameList);
      if (gameList.length) {
        const gameObj = gameList.find(obj => {
          return obj.code === group
        })
        this.tabList = gameObj.child
        // console.log(this.tabList);
      }
    },
    setGameType () {
      const pathStr = this.$route.path.split('/')
      this.reqData.vendorCode = pathStr[4]
      this.currentPage = pathStr[4]
    },
    pageSeach () {
      this.lastDataRefector(this.currentPage, 1)
    },
    setOldYn (data) {
      this.reqData.oldYn = data
    },
    setStartDate (date) {
      // console.log(date[0]);
      let _date = ''
      if (!date) {
        _date = new Date()
        _date.setHours(0, 0, 0)
      } else {
        _date = date[0]
      }
      this.startDefault = _date
      this.reqData.startDate = getDateStr(_date, 'yyyy-MM-dd HH:mm:ss')
    },
    setEndDate (date) {
      // console.log(date[0]);
      let _date = ''
      if (!date) {
        _date = new Date()
        _date.setHours(23, 59, 59)
      } else {
        _date = date[0]
      }
      this.endDefault = _date
      this.reqData.endDate = getDateStr(_date, 'yyyy-MM-dd HH:mm:ss')
    },
    async getBetList (code, pageNum) {
      if (pageNum) {
        this.reqData.page = pageNum
      }
      this.reqData.gameCategory = 'slot'
      if (code === 'all') {
        code = ''
      }
      this.reqData.vendorCode = code
      const data = this.reqData
      return await betList(data)
    },
    async getGameCode (reqData) {
      const data = reqData
      return await getCode(data)
    },
    gameCodeDetacher (list) {
      const set = new Set(list)
      const uniqueType = [...set]
      return uniqueType
    },
    async gameCodeConverter (list) {
      const convertList = {}
      for (const item of list) {
        convertList[item] = []
        const data = await this.getGameCode(item)
        convertList[item] = data
      }
      return convertList
    },
    goPage (pageNum) {
      const code = this.reqData.vendorCode
      this.lastDataRefector(code, pageNum)
    },
    setTitleName () {
      const locale = this.$i18n.locale
      this.tabList.forEach(item => {
        if (this.currentPage.toString() === item.code) {
          this.currentPageName = locale === 'ko' ? item.codeName : item['codeName' + locale.toUpperCase()]
        } else {
          if (this.currentPage === 'all') {
            this.currentPageName = locale === 'ko' ? '전체보기' : 'All View'
          }
        }
      })

      this.emitter.emit('gameTitle', this.currentPageName)
    },
    async lastDataRefector (code, pageNum) {
      this.emitter.emit('Loading', true)
      window.scrollTo(0, 0)
      if (!pageNum) {
        pageNum = 1
      }
      const listRes = await this.getBetList(code, pageNum)
      const pageInfo = listRes.data.pageInfo
      if (pageInfo) {
        this.pageInfo = pageInfo
      }
      const list = listRes.data.list
      const preBetType = []
      list.forEach(item => {
        const status = item.betStatus

        preBetType.push(item.betType)

        item.wlAmt = '0'
        const _wlAmt = item.betWinAmt - item.betAmt
        const type = item.type
        if (type === 'win') {
          item.result = '승리'
          item.wl = true
        } else if (type === 'lose') {
          item.result = '패배'
          item.wl = false
        } else if (type === 'draw') {
          item.result = '무'
          item.wl = false
        } else if (type === 'wait') {
          item.result = '대기'
          item.wl = false
        } else {
          item.result = '취소'
          item.wl = false
        }
        item.wlAmt = numberWithCommas(_wlAmt)

        /*switch (status) {
          case 'JUNGSANEND':
          case 'RESULTEND': {
            const _wlAmt = item.betWinAmt - item.betAmt
            if (_wlAmt >= 0) {
              item.result = '승리'
              item.wl = true
            } else {
              item.result = '패배'
              item.wl = false
            }
            item.wlAmt = numberWithCommas(_wlAmt)
            break
          }
          case 'WAIT': {
            item.result = '대기중'
            item.wlAmt = '0'
            break
          }
        }
        item.betAmt = numberWithCommas(item.betAmt)*/
      })
      const gTypes = this.gameCodeDetacher(preBetType)
      this.convertData = await this.gameCodeConverter(gTypes)
      // console.log(this.convertData)
      list.forEach(item => {
        const gameCode = this.convertData[item.betType]
        for (const _item of gameCode) {
          if (_item.code === item.gameType) {
            item.provider = _item.codeName
            item.gameType = _item.groupCode
          }
        }
        item.gameType = GAME_CODE_NAME[item.gameType]
      })
      this.betList = list
      this.emitter.emit('Loading', false)
    }
  },
  watch: {
    gameList: {
      handler (value) {
        this.setTabList()
      }
    },
    tabList: {
      handler (value) {
        if (this.tabList.length > 0 && this.currentPage) {
          this.setTitleName()
        }
      }
    },
    currentPage () {
      this.lastDataRefector(this.currentPage, 1)
    }
  },
  computed: {
    gameList () {
      return this.$store.getters.gameList
    }
  },
  async created () {
    this.emitter.emit('Loading', true)
    if (this.$route.query.id) {
      this.reqData.memId = this.$route.query.id
    }
    this.setStartDate()
    this.setEndDate()
    await this.setTabList()
    this.setGameType()
    await this.lastDataRefector(this.reqData.vendorCode, 1)
    this.emitter.emit('Loading', false)
  }
}
</script>

<style scoped>
.btnWrap {display: flex;align-items: center;justify-content: center;}
.copybtn {
  margin-left: 10px;
  width: 20px;
  height: 20px;
  background: url(~@/assets/img/icon_copy.svg) no-repeat;
  background-size: cover;
  cursor: pointer;
}
.main-sub-data-item {
  flex: 1 1 0;
  margin-right: 10px;
  border: 1px solid #c7c7c7;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 3px 3px 12px 0px #d1d1d1;
  display: flex;
}
.main-sub-data-item > div {
  padding: 8px 0;
  text-align: center;
  box-sizing: border-box;
}
.sub-data-item-title {
  background: #ab2926;
  color: #fff;
  font-weight: 700;
  width: 35%;
}
.sub-data-item-content {
  font-weight: 700;
  text-align: right;
  width: 65%;
}
.main-sub-data-item:last-child {
  margin-right: 0;
}

.btn-total-data {
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  white-space: nowrap;
  background: #2f4858;
  width: fit-content;
  border-radius: 3px;
  border: 2px solid #2f4858;
}
.btn-total-data:hover {
  color: #2f4858;
  background: #f1f1e6;
}
.btn-total-data > i {
  margin-right: 5px;
}
.btn-total-data > span {
  font-weight: 700;
  letter-spacing: 2px;
}
.tab-item {padding: 5px 7px;font-size: 12px;}
.w15 {width: 15%;}
.w15 button {white-space: nowrap;text-overflow: ellipsis;overflow: hidden;max-width: 150px;}
.ui-check-wrap i {border-radius: 5px;}
.rdc {
  color: #eb7a3f;
}
.blc {
  color: #82a6a4;
}
.box-w {background: #eae1ef;}
.box-t {background: #e9e9e9;}
.box-l {background: #ffc7c7;}
.casino {
  width: 55px;
  padding: 2px 7px;
  text-align: center;
  box-sizing: border-box;
  color:#fff;
  background: #eb7a3f;
  border-radius: 3px;;
}
.slot {
  width: 55px;
  padding: 2px 7px;
  text-align: center;
  box-sizing: border-box;
  color:#fff;
  background: #86822f;
  border-radius: 3px;
}

</style>
